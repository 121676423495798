import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Faq } from '../../store/Faq/faq.models';
import { Master } from '../../store/Masters/masters.models';
import { ProjectData } from '../../shared/modals/projectModal';

@Injectable({ providedIn: 'root' })
export class ProjectsService {
  constructor(private http: HttpClient) { }

  getProjects(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    Region: any = [],
    FromDate: any,
    ToDate: any,
    filter?: string,
    orderBy?: string,
    criteria?: string
  ): Observable<any> {
    console.log(Region)
    let url;
    url =
      `${AppConfigData.getProjectUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${criteria ? `&criteria=${criteria}` : ''}` +
      `${Array.isArray(Region) && Region.length > 0
        ? Region.map(
          (region: string) => `&Regions=${encodeURIComponent(region)}`
        ).join('')
        : ''
      }` +
      `${Region.length > 0 ? `&Regions=${Region}` : ''}` +
      `${FromDate ? `&FromDate=${FromDate}` : ''}` +
      `${ToDate ? `&ToDate=${ToDate}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  gerProjectBasedOnProjectId(userId: string): Observable<any> {
    let url;
    url = `${AppConfigData.getUserProjects + userId}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  createProjects(projectData: any): Observable<any> {
    const formData = new FormData();

    formData.append('Name', projectData.project_Name);
    formData.append('Code', projectData.project_Code);
    formData.append('ProjectTypeId', projectData.projectType);
    formData.append('RegionId', projectData.region);
    formData.append('Address', projectData.address);
    formData.append('Area', projectData.area);
    formData.append('CityId', projectData.city);
    formData.append('StateId', projectData.state);
    formData.append('PinCode', projectData.pincode);
    formData.append('ContactNumber', projectData.contactNumber);
    projectData.SelectedUtilityIds.forEach((id: string) => {
      formData.append('SelectedUtilityIds', id);
    });
    formData.append('LogoFile', projectData.LogoFile?.[0]?.rawFile ?? '');
    formData.append('RelayCutofflimit', projectData.relayCutofflimit ?? '');
    formData.append('Status', projectData.Status);
    formData.append('SocietyHeadName', projectData.society_name);
    formData.append('MerchantId', projectData.merchantId);
    formData.append('AccountName', projectData.accountName);
    formData.append('AccountNumber', projectData.accountNumber);
    formData.append('BankName', projectData.bankName);
    formData.append('BranchName', projectData.branchName);
    formData.append('IFSCCode', projectData.ifscCode);
    formData.append('AccountAddress', projectData.account_address);
    formData.append('StartDate', projectData.StartDate);
    formData.append(
      'File',
      projectData.cancelled_Cheque_file?.[0]?.rawFile ?? ''
    ); // Assuming this is a File object

    formData.append(
      'BillGenerationTypeId',
      projectData.BillGenerationTypeId ?? ''
    );
    formData.append('BillingTypeId', projectData.billingType ?? '');
    formData.append('MinRechargeAmount', projectData.minRechargeAmount ?? '');
    formData.append('MinWalletBalance', projectData.minWalletBalance ?? '');
    formData.append('ValveCutofflimit', projectData.valveCutofflimit ?? '');
    formData.append('MaxRechargeAmount', projectData.maxRechargeAmount ?? '');
    formData.append('BillDate', projectData.billDate ?? '');
    formData.append('ValveCutOffDate', projectData.ValveCutOffDate ?? '');
    formData.append('RelayCutOffDate', projectData.RelayCutOffDate ?? '');
    formData.append('IsMeterOnOff', projectData.meterOnOff ?? '');

    formData.append('waterConsumptionLimit', projectData.waterConsumptionLimit || '0');
    formData.append('gasConsumptionLimit', projectData.gasConsumptionLimit || '0');
    formData.append('electricityDGConsumptionLimit', projectData.electricityDGConsumptionLimit || '0');
    formData.append('electricityEBConsumptionLimit', projectData.electricityEBConsumptionLimit || '0');
    


    formData.append(
      'IsWalletEnabled', projectData.IsWalletEnabled
    );

    formData.append(
      'RequireIndependentDatabase',
      projectData.requireIndependentDatabase
    );
    formData.append('Password', projectData.db_password);
    formData.append('ServerName', projectData.db_serverName);
    formData.append('UserName', projectData.db_userName);
    // formData.append('GasProviderId', projectData.gasProvider ?? '');
    // formData.append(
    //   'ElectricityProviderId',
    //   projectData.electricityProvider ?? ''
    // );
    // formData.append('WaterProviderId', projectData.waterProvider ?? '');
    projectData?.gasProvider?.forEach((id: string) => {
      formData.append('GasProviderId', id);
    });
    projectData?.electricityProvider?.forEach((id: string) => {
      formData.append('ElectricityProviderId', id);
    });
    projectData?.waterProvider?.forEach((id: string) => {
      formData.append('WaterProviderId', id);
    });

    formData.append(
      'BluetoothDeviceIncluded',
      projectData.bluetoothDeviceIncluded
    );
    formData.append('MeterMenu', projectData.meterMenu);
    formData.append('EmailNotifications', projectData.emailNotifications);
    formData.append('SMSNotifications', projectData.smsNotifications);
    formData.append(
      'IsBillGenerateForEmptyResidentialUnit',
      projectData.IsBillGenerateForEmptyResidentialUnit
    );
    formData.append('isGIReadable', projectData.isGIReadable);
    return this.http.post<any>(AppConfigData.createProjectUrl, formData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  updateProjects(projectData: any, id: any): Observable<any> {
    console.log(projectData);
    const formData = new FormData();
    formData.append('Name', projectData.project_Name);
    formData.append('Code', projectData.project_Code);
    formData.append('ProjectTypeId', projectData.projectType);
    formData.append('RegionId', projectData.region);
    formData.append('Address', projectData.address);
    formData.append('Area', projectData.area);
    formData.append('CityId', projectData.city);
    formData.append('StateId', projectData.state);
    formData.append('PinCode', projectData.pincode);
    formData.append('ContactNumber', projectData.contactNumber);
    formData.append('StartDate', projectData.StartDate);
    // formData.append('SelectedUtilityIds', projectData.SelectedUtilityIds);
    // formData.append(
    //   'SelectedUtilityIds',
    //   JSON.stringify(projectData.SelectedUtilityIds)
    // );
    projectData.SelectedUtilityIds.forEach((id: string) => {
      formData.append('SelectedUtilityIds', id);
    });

    formData.append('LogoFile', projectData.LogoFile?.[0]?.rawFile ?? '');
    formData.append('RelayCutofflimit', projectData.relayCutofflimit ?? '');
    // formData.append('SocietyHeadName', projectData.societyHead);
    formData.append('SocietyHeadName', projectData.society_name);
    formData.append('MerchantId', projectData.merchantId);
    formData.append('AccountName', projectData.accountName);
    formData.append('AccountNumber', projectData.accountNumber);
    formData.append('BankName', projectData.bankName);
    formData.append('Status', projectData.Status);
    formData.append('BranchName', projectData.branchName);
    formData.append('IFSCCode', projectData.ifscCode);
    formData.append('AccountAddress', projectData.account_address);
    formData.append(
      'File',
      projectData.cancelled_Cheque_file?.[0]?.rawFile ?? ''
    );
    // Assuming this is a File object
    formData.append('BillingTypeId', projectData.billingType ?? '');
    formData.append(
      'BillGenerationTypeId',
      projectData.BillGenerationTypeId ?? ''
    );
    formData.append('MinRechargeAmount', projectData.minRechargeAmount ?? '');
    formData.append('MinWalletBalance', projectData.minWalletBalance ?? '');
    formData.append('ValveCutofflimit', projectData.valveCutofflimit ?? '');
    formData.append('MaxRechargeAmount', projectData.maxRechargeAmount ?? '');
    formData.append('BillDate', projectData.billDate ?? '');
    formData.append('ValveCutOffDate', projectData.ValveCutOffDate ?? '');

    formData.append('RelayCutOffDate', projectData.RelayCutOffDate ?? '');
    formData.append('IsMeterOnOff', projectData.meterOnOff ?? '');
    formData.append(
      'IsWalletEnabled', projectData?.IsWalletEnabled

    );
    formData.append(
      'RequireIndependentDatabase',
      projectData.requireIndependentDatabase
    );
    formData.append('Password', projectData.db_password);
    formData.append('ServerName', projectData.db_serverName);
    formData.append('UserName', projectData.db_userName);

    formData.append('waterConsumptionLimit', projectData.waterConsumptionLimit || '0');
    formData.append('gasConsumptionLimit', projectData.gasConsumptionLimit || '0');
    formData.append('electricityDGConsumptionLimit', projectData.electricityDGConsumptionLimit || '0');
    formData.append('electricityEBConsumptionLimit', projectData.electricityEBConsumptionLimit || '0');
    

    projectData?.gasProvider?.forEach((id: string) => {
      formData.append('GasProviderId', id);
    });
    projectData?.electricityProvider?.forEach((id: string) => {
      formData.append('ElectricityProviderId', id);
    });
    projectData?.waterProvider?.forEach((id: string) => {
      formData.append('WaterProviderId', id);
    });



    // formData.append('GasProviderId', projectData.gasProvider ?? '');
    // formData.append(
    //   'ElectricityProviderId',
    //   projectData.electricityProvider ?? ''
    // );
    // formData.append('WaterProviderId', projectData.waterProvider ?? '');
    formData.append(
      'BluetoothDeviceIncluded',
      projectData.bluetoothDeviceIncluded
    );
    formData.append('MeterMenu', projectData.meterMenu);
    formData.append('EmailNotifications', projectData.emailNotifications);
    formData.append('SMSNotifications', projectData.smsNotifications);
    formData.append(
      'IsBillGenerateForEmptyResidentialUnit',
      projectData.IsBillGenerateForEmptyResidentialUnit
    );
    formData.append('isGIReadable', projectData.isGIReadable);
    return this.http
      .post<any>(AppConfigData.updateProjectUrl + id, formData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deleteProjects(id: any): Observable<any> {
    return this.http.post<any>(AppConfigData.deleteProjectUrl + id, null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('deleteFaqs failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
}
