import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectState } from './projects.reducer';

export const getProjectState = createFeatureSelector<ProjectState>('projects');

export const getProjectsResponse = createSelector(
  getProjectState,
  (state: ProjectState) => state.getProjectsResponse
);

export const getAllProjectsResponse = createSelector(
  getProjectState,
  (state: ProjectState) => state.getAllProjectsResponse
);

export const createMasterResponse = createSelector(
  getProjectState,
  (state: ProjectState) => state.createMasterResponse
);

export const deleteProjectResponse = createSelector(
  getProjectState,
  (state: ProjectState) => state.deleteProjectResponse
);

export const updateProjectResponse = createSelector(
  getProjectState,
  (state: ProjectState) => state.updateProjectResponse
);

export const getProjectDataSuccessResponse = createSelector(
  getProjectState,
  (state: ProjectState) => state.getProjectDataSuccessResponse
);


