import { createAction, props } from '@ngrx/store';
import { Monitoring } from './monitoring.models';

// Monitorings creation
export const GetMonitoring = createAction(
  '[Monitoring] getMonitoring',
  props<{
    projectid: string;
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getMonitoringsSuccess = createAction(
  '[Monitoring] GetMonitoring Success',
  props<{ getMonitoringResponse: any }>()
);
export const getMonitoringsFailure = createAction(
  '[Monitoring] GetMonitoring Failure',
  props<{ getMonitoringError: any }>()
);

//get all monitoring
export const GetAllMonitoring = createAction(
  '[Monitoring] getAllMonitoring',
  props<{
    projectid: string;
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllMonitoringsSuccess = createAction(
  '[Monitoring] GetAllMonitoring Success',
  props<{ getAllMonitoringResponse: any }>()
);
export const getAllMonitoringsFailure = createAction(
  '[Monitoring] GetAllMonitoring Failure',
  props<{ getAllMonitoringError: any }>()
);

// Create Monitoring
export const createMonitoring = createAction(
  '[Monitoring] CreateMonitoring',
  props<{ MonitoringData: Monitoring }>()
);
export const CreateMonitoringSuccess = createAction(
  '[Monitoring] CreateMonitoring Success',
  props<{ createMonitoringResponse: any }>()
);
export const CreateMonitoringFailure = createAction(
  '[Monitoring] CreateMonitoring Failure',
  props<{ createMonitoringError: any }>()
);

// Update Monitoring
export const updateMonitoring = createAction(
  '[Monitoring] UpdateMonitoring',
  props<{ MonitoringData: Monitoring; id: string }>()
);
export const updateMonitoringSuccess = createAction(
  '[Monitoring] updateaster Success',
  props<{ updateMonitoringResponse: any }>()
);
export const updateMonitoringFailure = createAction(
  '[Monitoring] updateMonitoring Failure',
  props<{ updateMonitoringError: any }>()
);

//delete Monitoring
export const deleteMonitoring = createAction(
  '[Monitoring] deleteMonitoring',
  props<{ id: string }>()
);
export const deleteMonitoringSuccess = createAction(
  '[Monitoring] deleteMonitoring Success',
  props<{ deleteMonitoringResponse: any }>()
);
export const deleteMonitoringFailure = createAction(
  '[Monitoring] deleteMonitoring Failure',
  props<{ deleteMonitoringError: any }>()
);

//store reset
export const resetState = createAction('[App] Reset State');
