import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpProjectsViewComponent } from './rp-projects-view/rp-projects-view.component';
import { RpCreateProjectComponent } from './rp-create-project/rp-create-project.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { CanDeactivateGuard } from '../../core/guards/can-deactivate.guard';

const routes: Routes = [
  { path: 'projects', component: RpProjectsViewComponent },
  { path: 'projects/createProject', component: RpCreateProjectComponent, canDeactivate: [CanDeactivateGuard], },
  { path: 'projects/projectDetails', component: ProjectDetailsComponent },
  { path: 'projectDetails', component: ProjectDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule { }
