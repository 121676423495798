<!-- <app-rp-loader></app-rp-loader> -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body" style="min-height: 500px;">
          <!-- <h4 class="card-title mb-4">Project Details</h4> -->
          <div id="basic-example">
            <form [formGroup]="projectForm">
              <ng-stepper #cdkStepper [linear]="true" class="wizard sm-stepper">

                <cdk-step [optional]="false">
                  <ng-template cdkStepLabel>
                    <span class="number hiddennumber">1</span>
                    <span>Project Details</span>
                  </ng-template>
                  @if(cdkStepper.selectedIndex === 0){
                  <section>
                    <!-- <form> -->

                    <div class="row mb-2 d-sm-block d-md-none">
                      <h4 class="card-title mb-4 text-decoration-underline">Project Details</h4>
                    </div>

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="">Project Name <span class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="project_Name" id="project_Name"
                            placeholder="Enter Your Project Name">
                          @if((submitted || projectForm.controls['project_Name'].touched) &&
                          projectForm.controls['project_Name'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['project_Name'].errors['required']) {
                            <div>{{projectValidations.project_Name.required}}</div>}
                            @if(projectForm.controls['project_Name'].errors['minlength']) {
                            <div>{{projectValidations.project_Name.minLengthValidator}}</div>}
                            <!-- @if(projectForm.controls['project_Name'].errors['maxlength']) {
                                    <div>{{projectValidations.project_Name.MaxLengthValidator}}</div>} -->
                            @if(projectForm.controls['project_Name'].errors['maxlength']){
                            <div>{{projectValidations.project_Name.MaxLengthValidator}}</div>
                            }

                            @if(projectForm.controls['project_Name'].errors['pattern']) {
                            <div>{{projectValidations.project_Name.patternValidation}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="">Project Code</label>
                          <input type="text" class="form-control" formControlName="project_Code" id="project_Code"
                            placeholder="Enter Your Project Code">
                          @if((submitted || projectForm.controls['project_Code'].touched)&&
                          projectForm.controls['project_Code'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['project_Code'].errors['pattern']) {
                            <div>{{projectValidations.project_Code.patternValidation}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Project Type <span class="required">*</span></label>
                          <ng-select [items]="configData?.projectTypes" bindLabel="name" bindValue="id"
                            formControlName="projectType" id="projectType" [multiple]="false"></ng-select>

                          @if((submitted || projectForm.controls['projectType'].touched)&&
                          projectForm.controls['projectType'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['projectType'].errors['required']) {
                            <div>{{projectValidations.projectType.required}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Utility <span class="required">*</span></label>
                          <ng-select [items]="configData?.utilities" bindLabel="name" bindValue="id"
                            formControlName="SelectedUtilityIds" id="SelectedUtilityIds" [multiple]="true"></ng-select>

                          @if((submitted || projectForm.controls['SelectedUtilityIds'].touched)&&
                          projectForm.controls['SelectedUtilityIds'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['SelectedUtilityIds'].errors['required']) {
                            <div>{{projectValidations.utility.required}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Region <span class="required">*</span></label>
                          <ng-select [items]="configData?.regions" bindLabel="name" [placeholder]="'Select your Region'"
                            bindValue="id" formControlName="region" id="region" [multiple]="false"></ng-select>
                          @if((submitted || projectForm.controls['region'].touched)&&
                          projectForm.controls['region'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['region'].errors['required']) {
                            <div>{{projectValidations.region.required}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Project Address <span class="required">*</span></label>
                          <textarea id="basicpill-address-input" formControlName="address" id="address"
                            class="form-control" rows="2" placeholder="Enter Your Address"></textarea>
                          @if((submitted || projectForm.controls['address'].touched)&&
                          projectForm.controls['address'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['address'].errors['required']) {
                            <div>{{projectValidations.address.required}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Area/Locality <span class="required">*</span></label>
                          <input type="textarea" class="form-control" formControlName="area" id="area"
                            placeholder="Enter Your Area/Locality">
                          @if((submitted || projectForm.controls['area'].touched) &&
                          projectForm.controls['area'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['area'].errors['required']) {
                            <div>{{projectValidations.area.required}}</div>}
                            @if(projectForm.controls['area'].errors['pattern']) {
                            <div>{{projectValidations.area.patternValidation}}</div>}
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">State <span class="required">*</span></label>
                          <ng-select [items]="configData?.states" bindLabel="name" bindValue="id"
                            formControlName="state" id="state" [multiple]="false"></ng-select>
                          @if((submitted || projectForm.controls['state'].touched) &&
                          projectForm.controls['state'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['state'].errors['required']) {
                            <div>{{projectValidations.state.required}}</div>}
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">City <span class="required">*</span></label>
                          <!-- <ng-select  [items]="selectValue" formControlName="city" id="city"
                            [multiple]="false"></ng-select> -->
                          <ng-select [items]="cities" bindLabel="name" bindValue="id" formControlName="city"
                            placeholder="Select a city" [disabled]="!cities.length">
                          </ng-select>
                          @if((submitted || projectForm.controls['city'].touched) &&
                          projectForm.controls['city'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['city'].errors['required']) {
                            <div>{{projectValidations.city.required}}</div>}
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Pincode <span class="required">*</span></label>
                          <input type="textarea" class="form-control" formControlName="pincode" id="pincode" id=""
                            placeholder="Enter Your Pincode">
                          @if((submitted || projectForm.controls['pincode'].touched)&&
                          projectForm.controls['pincode'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['pincode'].errors['required']) {
                            <div>{{projectValidations.pincode.required}}</div>}
                            @if(projectForm.controls['pincode'].errors['minlength']) {
                            <div>{{projectValidations.pincode.minLengthValidator}}</div>}
                            @if(projectForm.controls['pincode'].errors['maxlength']) {
                            <div>{{projectValidations.pincode.MaxLengthValidator}}</div>}

                            @if(projectForm.controls['pincode'].errors['pattern']) {
                            <div>{{projectValidations.pincode.patternValidation}}</div>}
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Project Contact Number <span
                              class="required">*</span></label>
                          <input type="textarea" class="form-control" formControlName="contactNumber" id="contactNumber"
                            id="" placeholder="Enter Phone Number">
                          @if((submitted || projectForm.controls['contactNumber'].touched)&&
                          projectForm.controls['contactNumber'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['contactNumber'].errors['required']) {
                            <div>{{projectValidations.ContactNumber.required}}</div>}
                            @if(projectForm.controls['contactNumber'].errors['minlength']) {
                            <div>{{projectValidations.ContactNumber.mobile_mix_required_Validations}}</div>}
                            @if(projectForm.controls['contactNumber'].errors['maxlength']) {
                            <div>{{projectValidations.ContactNumber.mobile_mix_required_Validations}}</div>}

                            @if(projectForm.controls['contactNumber'].errors['pattern']) {
                            <div>{{projectValidations.ContactNumber.patternValidation}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-declaration-input">Project Status <span
                              class="required">*</span></label>
                          <ng-select [items]="configData?.statuses" (change)="selectionChange($event)"
                            formControlName="Status" id="Status" [multiple]="false"></ng-select>
                          @if((submitted || projectForm.controls['Status'].touched)&&
                          projectForm.controls['Status'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['Status'].errors['required']) {
                            <div>{{projectValidations.Status.required}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-declaration-input">Project Start Date <span
                              class="required">*</span></label>
                          <input type="text" class="form-control date" formControlName="StartDate"
                            placeholder="Select Date" name="singleDate" autocomplete="off"
                            (bsValueChange)="onDateRangeSelected($event)" bsDatepicker [bsConfig]="{
                                isAnimated: true, 
                                dateInputFormat: 'DD/MM/YYYY',
                                maxDate:today,
                                showWeekNumbers: false,
                              }" />
                          <span class="bx bx-calendar inputIcon mt-2" (click)="openCalendar()"></span>
                          <span class="bx bx-x inputIcon clear-icon mt-2" *ngIf="selectedDate"
                            (click)="clearDate()"></span>
                          <!-- <input class="form-control" type="date" formControlName="proectStartDate"
                            format="'dd/MM/yyyy'"> -->
                          @if((submitted || projectForm.controls['StartDate'].touched)&&
                          projectForm.controls['StartDate'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['StartDate'].errors['required']) {
                            <div>{{projectValidations.Status.required}}</div>}
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-6">
                        <label for="LogoFile">Upload Logo</label>
                        <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl" formControlName="LogoFile"
                          id="LogoFile" [batch]="false" [showFileList]="true" [multiple]="false"
                          accept=".png,.jpg,.jpeg">
                        </kendo-upload>

                        <span class="mt-3" *ngIf="projectDetails?.logoUrl">
                          <div class="logo-container">
                            <div class="logo-wrapper">
                              <img [src]="projectDetails?.logoUrl" class="project_logo" alt="Logo" />
                              <button type="button" class="btn download-btn" title="Download Logo"
                                (click)="downloadFile(projectDetails.logoUrl, projectDetails.logoFileName)">
                                <i class="fa fa-download"></i>
                              </button>
                            </div>
                          </div>
                        </span>
                      </div>



                      <div class="col-lg-6">
                        <label for="basicpill-declaration-input">Upload Cancelled Cheque</label>
                        <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
                          formControlName="cancelled_Cheque_file" id="cancelled_Cheque_file" [batch]="false"
                          [multiple]="false" accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx">
                        </kendo-upload>

                        <span class="mt-3" *ngIf="projectDetails?.societyHead?.fileUrl">
                          <div class="logo-container">
                            <div class="logo-wrapper">
                              <!-- Conditionally display the image or file icon -->
                              <ng-container *ngIf="isImageFile(projectDetails?.societyHead?.fileName); else fileIcon">
                                <img [src]="projectDetails?.societyHead?.fileUrl" class="project_logo"
                                  alt="Cancelled Cheque" />
                              </ng-container>
                              <!-- File icon template -->
                              <ng-template #fileIcon>
                                <i class="fa fa-file" aria-hidden="true" style="font-size: 40px;"></i>
                              </ng-template>

                              <!-- Download button -->
                              <button type="button" class="btn download-btn" title="Download File"
                                (click)="downloadFile(projectDetails?.societyHead?.fileUrl, projectDetails?.societyHead?.fileName)">
                                <i class="fa fa-download"></i>
                              </button>
                            </div>
                          </div>
                        </span>


                      </div>






                    </div>


                    <!-- </form> -->



                  </section>
                  <section class="mt-5">
                    <h4 class="card-title mb-4">Society Head Details</h4>
                    <!-- <form> -->
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="">Name </label>
                          <input type="text" class="form-control" formControlName="society_name" id="society_name"
                            placeholder="Enter Your Name">
                          @if((submitted || projectForm.controls['society_name'].touched)&&
                          projectForm.controls['society_name'].errors) {
                          <div class="invalid-feedback">
                            <!-- @if(projectForm.controls['society_name'].errors['required']) {
                            <div>{{projectValidations.society_name.required}}</div>} -->
                            @if(projectForm.controls['society_name'].errors['minlength']) {
                            <div>{{projectValidations.project_Name.minLengthValidator}}</div>}
                            @if(projectForm.controls['society_name'].errors['maxlength']) {
                            <div>{{projectValidations.project_Name.MaxLengthValidator}}</div>}
                            @if(projectForm.controls['society_name'].errors['pattern']) {
                            <div>{{projectValidations.society_name.patternValidation}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Merchant ID
                            <span *ngIf="projectForm.controls['Status'].value =='Active'" class="required">*</span>
                          </label>
                          <input type="text" class="form-control" formControlName="merchantId" id="merchantId"
                            placeholder="Enter Your Merchant ID">
                          @if((submitted || projectForm.controls['merchantId'].touched)&&
                          projectForm.controls['merchantId'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['merchantId'].errors['required']) {
                            <div>{{projectValidations.merchantId.required}}</div>}
                            @if(projectForm.controls['merchantId'].errors['pattern']) {
                            <div>{{projectValidations.merchantId.patternValidation}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Account Name </label>
                          <input type="text" class="form-control" formControlName="accountName" id="accountName"
                            placeholder="Enter Your Account Name">
                          @if((submitted || projectForm.controls['accountName'].touched)&&
                          projectForm.controls['accountName'].errors) {
                          <div class="invalid-feedback">
                            <!-- @if(projectForm.controls['accountName'].errors['required']) {
                            <div>{{projectValidations.accountName.required}}</div>} -->

                            @if (projectForm.controls['accountName'].errors['pattern']) {
                            <div>{{projectValidations.accountName.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Account Number </label>
                          <input type="text" class="form-control" formControlName="accountNumber" id="accountNumber"
                            placeholder="Enter Your Account Number">
                          @if((submitted || projectForm.controls['accountNumber'].touched)&&
                          projectForm.controls['accountNumber'].errors) {
                          <div class="invalid-feedback">
                            <!-- @if(projectForm.controls['accountNumber'].errors['required']) {
                            <div>{{projectValidations.accountNumber.required}}</div>} -->
                            @if (projectForm.controls['accountNumber'].errors['pattern']) {
                            <div>{{projectValidations.accountNumber.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Bank Name </label>
                          <input type="text" class="form-control" formControlName="bankName" id="bankName"
                            placeholder="Enter Your Bank Name">
                          @if((submitted || projectForm.controls['bankName'].touched)&&
                          projectForm.controls['bankName'].errors) {
                          <div class="invalid-feedback">
                            <!--  @if(projectForm.controls['bankName'].errors['required']) {
                            <div>{{projectValidations.bankName.required}}</div>} -->
                            @if (projectForm.controls['bankName'].errors['pattern']) {
                            <div>{{projectValidations.bankName.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Branch Name </label>
                          <input type="text" class="form-control" formControlName="branchName" id="branchName"
                            placeholder="Enter Your Branch Name">
                          @if((submitted || projectForm.controls['branchName'].touched)&&
                          projectForm.controls['branchName'].errors) {
                          <div class="invalid-feedback">
                            <!--  @if(projectForm.controls['branchName'].errors['required']) {
                            <div>{{projectValidations.branchName.required}}</div>} -->
                            @if (projectForm.controls['branchName'].errors['pattern']) {
                            <div>{{projectValidations.branchName.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">IFSC Code </label>
                          <input type="text" class="form-control" formControlName="ifscCode" id="ifscCode"
                            placeholder="Enter Your IFSC Code">
                          @if((submitted || projectForm.controls['ifscCode'].touched) &&
                          projectForm.controls['ifscCode'].errors) {
                          <div class="invalid-feedback">
                            <!--  @if(projectForm.controls['ifscCode'].errors['required']) {
                             <div>{{projectValidations.ifscCode.required}}</div>} -->
                            @if (projectForm.controls['ifscCode'].errors['pattern']) {
                            <div>{{projectValidations.ifscCode.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-lastname-input">Address</label>
                          <textarea id="basicpill-address-input" class="form-control" formControlName="account_address"
                            id="account_address" rows="2" placeholder="Enter Your Address"></textarea>
                        </div>
                      </div>

                    </div>

                    <!-- </form> -->


                  </section>
                  <section class="mt-5">
                    <div class="row">


                    </div>




                    <ul class="list-inline wizard d-flex justify-content-end mt-5">
                      <!-- <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-primary" cdkStepperPrevious>Previous</button></li> -->
                      <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                          cdkStepperNext>Next</button></li>
                    </ul>
                  </section>




                  }
                </cdk-step>

                <cdk-step [optional]="true">
                  <ng-template cdkStepLabel>
                    <span class="number hiddennumber">2</span>
                    <span>Billing & Recharge</span>
                  </ng-template>
                  @if(cdkStepper.selectedIndex === 1){
                  <section>
                    <!-- <form> -->
                    <div class="row mb-2 d-sm-block d-md-none">
                      <h4 class="card-title mb-4 text-decoration-underline">Billing & Recharge</h4>
                    </div>

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-pancard-input">Bill Type
                            <span class="required">*</span></label>
                          <ng-select [items]="configData?.billingTypes" bindLabel="name" bindValue="id"
                            (change)="billingTypeChange($event)" formControlName="billingType" id="billingType"
                            [multiple]="false" [deselectOnClick]="true"></ng-select>
                          @if((submitted || projectForm.controls['billingType'].touched) &&
                          projectForm.controls['billingType'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['billingType'].errors['required']) {
                            <div>{{projectValidations.billingType.required}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4" *ngIf="isDisplayFieldsBasedOnBillType">
                        <div class="mb-3">
                          <label for="basicpill-declaration-input">Bill generation Type<span
                              class="required">*</span></label>
                          <ng-select [items]="configData.billGenerationTypes" formControlName="BillGenerationTypeId"
                            bindLabel="name" bindValue="id" [multiple]="false"></ng-select>
                          @if((submitted || projectForm.controls['BillGenerationTypeId'].touched)&&
                          projectForm.controls['BillGenerationTypeId'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['BillGenerationTypeId'].errors['required']) {
                            <div>{{projectValidations.BillGenerationTypeId.required}}</div>}
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="basicpill-pancard-input"> </label>
                          <div>
                            <input class="form-check-input" type="checkbox" formControlName="IsWalletEnabled"
                              id="IsWalletEnabled">
                            <label class="form-check-label ms-2" for="IsWalletEnabled">Wallet </label>
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-4" *ngIf="isDisplayFieldsBasedOnBillType">
                        <div class="mb-3">
                          <label for="basicpill-declaration-input">Project Billing Date<span
                              class="required">*</span></label>
                          <!-- <input class="form-control" type="date" formControlName="billDate" id="billDate"
                                                  value="2019-08-19" id="example-date-input"> -->

                          <ng-select [items]="projectBillingDates" formControlName="billDate" id="billDate"
                            [multiple]="false"></ng-select>
                          @if((submitted || projectForm.controls['billDate'].touched)&&
                          projectForm.controls['billDate'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['billDate'].errors['required']) {
                            <div>{{projectValidations.billDate.required}}</div>}
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-4" *ngIf="displayfieldsBasedonWalletCheckBox">
                        <div class="mb-3">
                          <label for="basicpill-vatno-input">Minimum Recharge Amount (₹) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="minRechargeAmount"
                            id="minRechargeAmount" placeholder="Enter Minimum Recharge Amount ">
                          @if((submitted || projectForm.controls['minRechargeAmount'].touched)&&
                          projectForm.controls['minRechargeAmount'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['minRechargeAmount'].errors['required']) {
                            <div>{{projectValidations.minRechargeAmount.required}}</div>}
                            @if (projectForm.controls['minRechargeAmount'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.minRechargeAmount.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-4" *ngIf="displayfieldsBasedonWalletCheckBox">
                        <div class="mb-3">
                          <label for="basicpill-cstno-input">Minimum Wallet Balance (₹)<span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="minWalletBalance"
                            id="minWalletBalance" placeholder="Enter Minimum Wallet Balance" id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['minWalletBalance'].touched)&&
                          projectForm.controls['minWalletBalance'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['minWalletBalance'].errors['required']) {
                            <div>{{projectValidations.minWalletBalance.required}}</div>}
                            @if (projectForm.controls['minWalletBalance'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.minWalletBalance.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="displayfieldsBasedonWalletCheckBox">
                        <div class="mb-3">
                          <label for="basicpill-companyuin-input">Maximum Recharge Amount (₹)<span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="maxRechargeAmount"
                            id="maxRechargeAmount" placeholder="Enter Maximum Recharge Amount"
                            id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['maxRechargeAmount'].touched)&&
                          projectForm.controls['maxRechargeAmount'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['maxRechargeAmount'].errors['required']) {
                            <div>{{projectValidations.maxRechargeAmount.required}}</div>}
                            @if (projectForm.controls['maxRechargeAmount'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.maxRechargeAmount.patternValidation}}</div>
                            }

                          </div>}

                          <div class="invalid-feedback">
                            <div
                              *ngIf="parsing(projectForm.controls['minRechargeAmount'].value) > parsing(projectForm.controls['maxRechargeAmount'].value)">
                              <div> Please enter maximum recharge amount greater than minimum recharge amount</div>
                            </div>
                          </div>

                        </div>
                      </div>



                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.WATER)">
                        <div class="mb-3">
                          <label for="basicpill-companyuin-input">Water Consumption Limit (L) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="waterConsumptionLimit"
                            id="waterConsumptionLimit" placeholder="Enter water Consumption Limit"
                            id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['waterConsumptionLimit'].touched)&&
                          projectForm.controls['waterConsumptionLimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['waterConsumptionLimit'].errors['required']) {
                            <div>{{projectValidations.waterConsumptionLimit.required}}</div>}
                            @if (projectForm.controls['waterConsumptionLimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.waterConsumptionLimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.GAS)">
                        <div class="mb-3">
                          <label for="basicpill-companyuin-input">Gas Consumption Limit (m³) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="gasConsumptionLimit"
                            id="gasConsumptionLimit" placeholder="Enter Gas Consumption Limit"
                            id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['gasConsumptionLimit'].touched)&&
                          projectForm.controls['gasConsumptionLimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['gasConsumptionLimit'].errors['required']) {
                            <div>{{projectValidations.gasConsumptionLimit.required}}</div>}
                            @if (projectForm.controls['gasConsumptionLimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.gasConsumptionLimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.ELECTRICITY)">
                        <div class="mb-3">
                          <label for="basicpill-companyuin-input">Electricity DG Consumption Limit (kWh) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="electricityDGConsumptionLimit"
                            id="electricityDGConsumptionLimit" placeholder="Enter Electricity Dg Consumption Limit"
                            id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['electricityDGConsumptionLimit'].touched)&&
                          projectForm.controls['electricityDGConsumptionLimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['electricityDGConsumptionLimit'].errors['required']) {
                            <div>{{projectValidations.electricityDGConsumptionLimit.required}}</div>}
                            @if (projectForm.controls['electricityDGConsumptionLimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.electricityDGConsumptionLimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.ELECTRICITY)">
                        <div class="mb-3">
                          <label for="basicpill-companyuin-input">Electricity EB Consumption Limit (kWh) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="electricityEBConsumptionLimit"
                            id="electricityEBConsumptionLimit" placeholder="Enter Electricity Eb Consumption Limit"
                            id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['electricityEBConsumptionLimit'].touched)&&
                          projectForm.controls['electricityEBConsumptionLimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['electricityEBConsumptionLimit'].errors['required']) {
                            <div>{{projectValidations.electricityEBConsumptionLimit.required}}</div>}
                            @if (projectForm.controls['electricityEBConsumptionLimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.electricityEBConsumptionLimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>


                      <!-- <div class="row"> -->


                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.GAS)&&!isDisplayFieldsBasedOnBillType">
                        <div class="mb-3">
                          <label for="basicpill-servicetax-input">Valve Cut Off Limit (₹) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="valveCutofflimit"
                            id="valveCutofflimit" placeholder="Enter Valve Cut Limit" id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['valveCutofflimit'].touched) &&
                          projectForm.controls['valveCutofflimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['valveCutofflimit'].errors['required']) {
                            <div>{{projectValidations.valveCutofflimit.required}}</div>}
                            @if (projectForm.controls['valveCutofflimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.valveCutofflimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.ELECTRICITY)&&!isDisplayFieldsBasedOnBillType">
                        <div class="mb-3">
                          <label for="basicpill-servicetax-input">Relay Cut-off Limit (₹) <span
                              class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="relayCutofflimit"
                            id="relayCutofflimit" placeholder="Enter Relay Cut-off Limit" id="basicpill-vatno-input">
                          @if((submitted || projectForm.controls['relayCutofflimit'].touched) &&
                          projectForm.controls['relayCutofflimit'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['relayCutofflimit'].errors['required']) {
                            <div>{{projectValidations.relayCutofflimit.required}}</div>}
                            @if (projectForm.controls['relayCutofflimit'].errors['numberWithDecimals']) {
                            <div>{{projectValidations.relayCutofflimit.patternValidation}}</div>
                            }
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-4" *ngIf="isDisplayFieldsBasedOnBillType">
                        <div class="mb-3">
                          <label for="basicpill-declaration-input">Valve CutOff Date<span
                              class="required">*</span></label>
                          <!-- <input class="form-control" type="date" formControlName="billDate" id="billDate"
                                                  value="2019-08-19" id="example-date-input"> -->

                          <ng-select [items]="projectBillingDates" formControlName="ValveCutOffDate"
                            id="ValveCutOffDate" [multiple]="false"></ng-select>
                          @if((submitted || projectForm.controls['ValveCutOffDate'].touched)&&
                          projectForm.controls['ValveCutOffDate'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['ValveCutOffDate'].errors['required']) {
                            <div>{{projectValidations.ValveCutOffDate.required}}</div>}
                          </div>}
                        </div>
                      </div>


                      <div class="col-lg-4" *ngIf="isDisplayFieldsBasedOnBillType">
                        <div class="mb-3">
                          <label for="basicpill-declaration-input">Relay CutOff Date<span
                              class="required">*</span></label>
                          <!-- <input class="form-control" type="date" formControlName="billDate" id="billDate"
                                                  value="2019-08-19" id="example-date-input"> -->

                          <ng-select [items]="projectBillingDates" formControlName="RelayCutOffDate"
                            id="RelayCutOffDate" [multiple]="false"></ng-select>
                          @if((submitted || projectForm.controls['RelayCutOffDate'].touched)&&
                          projectForm.controls['RelayCutOffDate'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['RelayCutOffDate'].errors['required']) {
                            <div>{{projectValidations.RelayCutOffDate.required}}</div>}
                          </div>}
                        </div>
                      </div>

                    </div>




                    <!-- </div> -->

                    <!-- </form> -->

                    <ul class="list-inline wizard d-flex justify-content-end mb-0">
                      <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                          cdkStepperPrevious>Previous</button></li>
                      <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                          cdkStepperNext>Next</button></li>
                    </ul>
                  </section>
                  }
                </cdk-step>


                <cdk-step *ngIf="!isprojectEdit" [optional]="true">
                  <ng-template cdkStepLabel>
                    <span class="number hiddennumber">3</span>
                    <span>Database </span>
                  </ng-template>
                  <!-- @if(cdkStepper.selectedIndex === 2){ -->
                  <section *ngIf="!isprojectEdit">
                    <div>
                      <!-- <form> -->
                      <div class="row mb-2 d-sm-block d-md-none">
                        <h4 class="card-title mb-4 text-decoration-underline">Database</h4>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="mb-3">
                            <div class="form-check mb-3">
                              <input class="form-check-input" type="checkbox"
                                formControlName="requireIndependentDatabase" id="requireIndependentDatabase"
                                id="formCheck1">
                              <label class="form-check-label" for="formCheck1">
                                Requires independent database
                              </label>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div class="row">


                        <div class="col-lg-4">
                          <div class="mb-3">
                            <label for="basicpill-expiration-input">Database Username
                              @if(projectForm.value.requireIndependentDatabase)
                              {<span class="required">*</span>}</label>
                            <input type="text" formControlName="db_userName" id="db_userName" class="form-control"
                              id="basicpill-expiration-input">
                            @if((submitted || projectForm.controls['db_userName'].touched) &&
                            projectForm.controls['db_userName'].errors) {
                            <div class="invalid-feedback">
                              @if(projectForm.controls['db_userName'].errors['pattern']) {
                              <div>{{projectValidations.db_userName.patternValidation}}</div>}
                              @if(projectForm.controls['db_userName'].errors['required']) {
                              <div>{{projectValidations.db_userName.required}}</div>}
                            </div>}
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="mb-3">
                            <label for="basicpill-cardno-input">Database Password
                              @if(projectForm.value.requireIndependentDatabase)
                              {<span class="required">*</span>}</label>
                            <input type="text" class="form-control" formControlName="db_password" id="db_password">

                            @if((submitted || projectForm.controls['db_password'].touched) &&
                            projectForm.controls['db_password'].errors) {
                            <div class="invalid-feedback">

                              @if(projectForm.controls['db_password'].errors['required']) {
                              <div>{{projectValidations.db_password.required}}</div>}
                            </div>}
                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="mb-3">
                            <label for="basicpill-card-verification-input">Database Server
                              Name@if(projectForm.value.requireIndependentDatabase)
                              {<span class="required">*</span>} </label>
                            <input type="text" class="form-control" formControlName="db_serverName" id="db_serverName">
                            @if((submitted || projectForm.controls['db_serverName'].touched) &&
                            projectForm.controls['db_serverName'].errors) {
                            <div class="invalid-feedback">
                              @if(projectForm.controls['db_serverName'].errors['pattern']) {
                              <div>{{projectValidations.db_serverName.patternValidation}}</div>}
                              @if(projectForm.controls['db_serverName'].errors['required']) {
                              <div>{{projectValidations.db_serverName.required}}</div>}
                            </div>}
                          </div>
                        </div>



                      </div>

                      <!-- </form> -->
                    </div>

                    <ul class="list-inline wizard d-flex justify-content-end mb-0">
                      <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                          cdkStepperPrevious>Previous</button></li>
                      <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                          cdkStepperNext>Next</button></li>
                    </ul>
                  </section>
                  <!-- } -->
                </cdk-step>

                <cdk-step [optional]="true">
                  <ng-template cdkStepLabel>
                    <span class="number hiddennumber">4</span>
                    <span>Provider Settings</span>
                  </ng-template>
                  <!-- @if(cdkStepper.selectedIndex === 3){ -->
                  <section>
                    <div class="row mb-2 d-sm-block d-md-none">
                      <h4 class="card-title mb-4 text-decoration-underline">Provider Settings</h4>
                    </div>
                    <div class="row">
                      <div *ngIf="f['SelectedUtilityIds'].value==null||f['SelectedUtilityIds'].value.length==0">
                        {{projectValidations.provider_settings.required}}</div>
                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.GAS)">
                        <div class="mb-3">
                          <label for="basicpill-cardno-input">Gas Provider name <span class="required">*</span></label>
                          <ng-select [items]="configData?.gasProviders" bindLabel="name" bindValue="id"
                            formControlName="gasProvider" id="gasProvider" [multiple]="true"></ng-select>
                          @if((submitted || projectForm.controls['gasProvider'].touched) &&
                          projectForm.controls['gasProvider'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['gasProvider'].errors['required']) {
                            <div>{{projectValidations.gasProvider.required}}</div>}
                          </div>}

                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.ELECTRICITY)">
                        <div class="mb-3">
                          <label for="basicpill-card-verification-input">Electricity Provider <span
                              class="required">*</span> </label>
                          <ng-select [items]="configData?.electricityProviders" bindLabel="name" bindValue="id"
                            formControlName="electricityProvider" id="electricityProvider"
                            [multiple]="true"></ng-select>
                          @if((submitted || projectForm.controls['electricityProvider'].touched) &&
                          projectForm.controls['electricityProvider'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['electricityProvider'].errors['required']) {
                            <div>{{projectValidations.electricityProvider.required}}</div>}
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-4" *ngIf="isDisplay(UtilityType.WATER)">
                        <div class="mb-3">
                          <label for="basicpill-expiration-input">Water Provider <span class="required">*</span>
                          </label>
                          <ng-select [items]="configData?.waterProviders" bindLabel="name" bindValue="id"
                            formControlName="waterProvider" id="waterProvider" [multiple]="true"></ng-select>
                          @if((submitted || projectForm.controls['waterProvider'].touched)&&
                          projectForm.controls['waterProvider'].errors) {
                          <div class="invalid-feedback">
                            @if(projectForm.controls['waterProvider'].errors['required']) {
                            <div>{{projectValidations.waterProvider.required}}</div>}
                          </div>}
                        </div>
                      </div>

                    </div>

                    <ul class="list-inline wizard d-flex justify-content-end mb-10">
                      <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                          cdkStepperPrevious>Previous</button></li>
                      <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                          cdkStepperNext>Next</button></li>
                    </ul>
                  </section>
                  <!-- } -->
                </cdk-step>

                <cdk-step [optional]="true">
                  <ng-template cdkStepLabel>
                    <span class="number hiddennumber">5</span>
                    <span>Notifications & Settings</span>
                  </ng-template>
                  <!-- @if(cdkStepper.selectedIndex === 4){ -->
                  <section>
                    <div class="row mb-2 d-sm-block d-md-none">
                      <h4 class="card-title mb-4 text-decoration-underline">Notifications & Settings</h4>
                    </div>

                    <div class="row">
                      <div class="col-lg-3 d-flex align-items-center">
                        <div class="mb-3">

                          <input class="form-check-input" type="checkbox" formControlName="bluetoothDeviceIncluded"
                            id="bluetoothDeviceIncluded" id="bluetoothDeviceIncluded">

                          <label class="form-check-label ms-2"> Bluetooth Devices Included</label>
                        </div>
                      </div>

                      <div class="col-lg-4 d-flex align-items-center">
                        <div class="mb-3">

                          <input class="form-check-input" type="checkbox" formControlName="meterMenu" id="meterMenu"
                            id="meterMenu">
                          <label class="form-check-label ms-2"> Meter On/Off menu for Consumer</label>
                        </div>
                      </div>

                      <div class="col-lg-2 d-flex align-items-center">

                        <div class="form-check mb-3 ">
                          <input class="form-check-input" type="checkbox" formControlName="emailNotifications"
                            id="emailNotifications" id="formCheck1">
                          <label class="form-check-label" for="formCheck1">
                            Email Notification
                          </label>
                        </div>

                      </div>

                      <div class="col-lg-3 d-flex align-items-center">


                        <div class="form-check mb-3">
                          <input class="form-check-input" formControlName="smsNotifications" id="smsNotifications"
                            type="checkbox" id="formCheck2">
                          <label class="form-check-label" for="formCheck2">
                            SMS Notifications
                          </label>
                        </div>
                      </div>

                      <div class="col-lg-3 d-flex align-items-center">


                        <div class="form-check mb-3">
                          <input class="form-check-input" formControlName="IsBillGenerateForEmptyResidentialUnit"
                            id="IsBillGenerateForEmptyResidentialUnit" type="checkbox" id="formCheck2">
                          <label class="form-check-label" for="formCheck2">
                            Generate bills for empty flats in the name of admin
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-3 d-flex align-items-center">


                        <div class="form-check mb-3" *ngIf="selectedBillType && selectedBillType?.name== 'Postpaid'">
                          <input class="form-check-input" formControlName="meterOnOff" id="meterOnOff" type="checkbox"
                            id="formCheck2">
                          <label class="form-check-label" for="formCheck2">
                            Stop sending meter off request during billing
                          </label>
                        </div>
                        <div class="form-check mb-3" *ngIf="selectedBillType && selectedBillType?.name== 'Prepaid'">
                          <input class="form-check-input" formControlName="meterOnOff" id="meterOnOff" type="checkbox"
                            id="formCheck2">
                          <label class="form-check-label" for="formCheck2">
                            Stop sending meter off request for -ve wallet
                          </label>
                        </div>
                      </div>

                    </div>


                    <ul class="list-inline wizard d-flex justify-content-end mb-0">
                      <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                          cdkStepperPrevious>Previous</button></li>
                      <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                          cdkStepperNext>Next</button>
                      </li>
                    </ul>

                  </section>
                  <!-- } -->
                </cdk-step>

                <cdk-step [optional]="true">
                  <ng-template cdkStepLabel>
                    <span class="number hiddennumber">6</span>
                    <span>Gateway Settings</span>
                  </ng-template>

                  <!-- @if(cdkStepper.selectedIndex === 5) { -->
                  <div class="row">
                    <div class="col-lg-3 d-flex align-items-center">
                      <div class="mb-3">

                        <input class="form-check-input" type="checkbox" formControlName="isGIReadable" id="isGIReadable"
                          id="bluetoothDeviceIncluded">

                        <label class="form-check-label ms-2">Display Gateway For Admin</label>
                      </div>
                    </div>
                  </div>
                  <!-- } -->
                  @if(cdkStepper.selectedIndex === 5&&typeOf=='Edit'&&projectDetails?.isGIReadable){
                  <section>
                    <div class="row mb-2 d-sm-block d-md-none">
                      <h4 class="card-title mb-4 text-decoration-underline">Gateway Information</h4>
                    </div>

                    <ng-template #template let-anchor>
                      <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>
                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                      (mouseover)="showTooltip($event)">
                      <kendo-grid [data]="gatewaygridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                        [pageable]="{
                    buttonCount: buttonCount,
                    info: info,
                    type: type,
                    pageSizes: pageSizes,
                    previousNext: previousNext
                  }" [reorderable]="true" filterable="menu" [filter]="gateWayState.filter" [skip]="gateWayState.skip"
                        (filterChange)="onGateWayFilterChange($event)" (sortChange)="onGatewaySortChange($event)"
                        [sort]="gateWayState.sort" [columnMenu]="{ filter: true }" [resizable]="true"
                        scrollable="scrollable" [selectable]="selectableSettings"
                        (pageChange)="gatewayPageChange($event)" (dataStateChange)="dataStateChange($event)"
                        (selectionChange)="gatewayOnSelect($event)">


                        <ng-template kendoGridToolbarTemplate>

                          <div class="search_icon">
                            <kendo-textbox [style.width.px]="250" [style.height.px]="35" [(ngModel)]="gateWaySearch"
                              placeholder="Search in all columns...">
                            </kendo-textbox>

                            <button class="btn btn-primary btn-md border-left-none" (click)="ongatewayFilter($event)">
                              <i class="fa fa-search"></i>
                            </button>
                          </div>
                          <kendo-grid-spacer></kendo-grid-spacer>

                          <button class="btn btn-outline-primary btn-sm me-2" (click)="addNewGateWay()">Add New
                          </button>
                          <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                            (click)="onExport('Excel')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>


                          <!-- <button kendoGridPDFCommand class="btn btn-light" (click)="gatewayonExport()">
                          <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                        </ng-template>
                        <!-- Excel Export -->

                        <!-- Pdf   start-->
                        <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                          <kendo-grid
                            [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:gatewaygridData.data"
                            #pdfGrid>
                            <kendo-grid-column field="serialNumber" title="Gateway Serial Number"></kendo-grid-column>
                            <kendo-grid-column field="name" title="Gateway"></kendo-grid-column>
                            <kendo-grid-column field="activeStatus" title="Gateway On/Off">
                            </kendo-grid-column>

                            <kendo-grid-pdf fileName="gateway.pdf" [allPages]="true" paperSize="A4"
                              [landscape]="true"></kendo-grid-pdf>
                            <kendo-grid-excel fileName="gateway.xlsx"></kendo-grid-excel>

                          </kendo-grid>
                        </div>

                        <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                          [width]="40"></kendo-grid-checkbox-column>

                        <kendo-grid-column field="serialNumber" title="Gateway Serial Number"></kendo-grid-column>
                        <kendo-grid-column field="name" title="Gateway"></kendo-grid-column>

                        <kendo-grid-column field="isActive" title="Gateway On/Off" filter="boolean">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.isActive?'ON':'OFF'}}

                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-command-column title="" [columnMenu]="false" [width]="90">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="d-flex ">
                              <!-- Edit Gateway Icon -->
                              <span tooltip="Go to GateWay Edit" class="me-2" (click)="editGateWay(dataItem)"
                                style="cursor: pointer;">
                                <i class="fas fa-edit text-primary"></i>
                              </span>

                              <!-- Delete Gateway Icon -->
                              <span tooltip="Delete GateWay" class="ms-2" (click)="deleteGateway(dataItem)"
                                style="cursor: pointer;">
                                <i class="fas fa-trash-alt text-danger"></i>
                              </span>
                            </div>
                          </ng-template>
                        </kendo-grid-command-column>

                      </kendo-grid>
                    </div>

                  </section>
                  }


                  <ul class="list-inline wizard d-flex justify-content-end mb-0 mt-4">
                    @if(submitted&&!projectForm.valid ){<div class="required" style="margin-right: 5%;">
                      {{projectValidations.required_all_mandatoryFields}}</div>}
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                    @if(!projectDetails?.name) {<li (click)="saveProject('Create')" class="next list-inline-item"
                      aria-disabled="false">
                      <button class="btn btn-primary" cdkStepperNext>Save Project</button>
                    </li>
                    }@else{
                    <li (click)="saveProject('Edit')" class="next list-inline-item" aria-disabled="false"><button
                        class="btn btn-primary" cdkStepperNext>Update Project</button></li>}
                  </ul>
                </cdk-step>

              </ng-stepper>
            </form>
          </div>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>




</div>