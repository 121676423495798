<!-- Add Gateways modal -->

<!-- <div class="modal-dialog modal-dialog-centered"> -->
<div class="modal-content">
    <div class="modal-header bg-primary text-white">
        <h5 class="modal-title"> {{ gatewayUpdateData?.id? 'Update Gateway' : 'Add Gateway' }} </h5>
        <button type="button" (click)="dismissModal()" class="btn-close text-white" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal" [formGroup]="creategatewayForm">
            <div class="mb-3">
                <label>Gateway Serial Number</label>
                <input type="text" class="form-control" formControlName="serialNumber" id="serialNumber" />
                @if(submitted && gatewayControls['serialNumber'].errors){
                <div class="invalid-feedback">
                    @if(gatewayControls['serialNumber'].errors['required']){
                    <div>{{prifileValidations.serialNumber_required_Validations}}</div>}

                    @if(gatewayControls['serialNumber'].errors['minlength']){
                    <div>{{prifileValidations.min_required_Validations}}</div>
                    }
                    @if(gatewayControls['serialNumber'].errors['maxlength']){
                    <div>{{prifileValidations.min_required_Validations}}</div>
                    }
                    @if(gatewayControls['serialNumber'].errors['pattern']){
                    <div>{{prifileValidations.allow_spaces}}</div>}
                </div>}
            </div>
            <div class="mb-3">
                <label>Gateway</label>
                <ng-select [items]="configData
                    " formControlName="gatewayName" (change)="valueChange($event)" [multiple]="false" bindLabel="name"
                    bindValue="id" [placeholder]="'Select...'"></ng-select>
                <!--  <kendo-dropdownlist [data]="gatewayProvidersList" [(ngModel)]="selectedGateway"
                        formControlName="gatewayName" textField="name" valueField="id"
                        (valueChange)="valueChange($event)" [filterable]="true"
                        (filterChange)="gateWayNameFilter($event)">
                    </kendo-dropdownlist> -->
                <!-- <input type="text" class="form-control" formControlName="gatewayName" id="gatewayName" /> -->
            </div>


        </form>
    </div>
    <div class="modal-footer">
        @if(gatewayUpdateData?.id){
        <button type="button" class="btn btn-primary waves-effect waves-light"
            (click)="saveGateway('Update')">Update</button>}
        @else{
        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="saveGateway('Create')">Save
            Gateway</button>}

        <!-- Add Gateways modal End  -->
    </div>
</div>
<!-- </div> -->