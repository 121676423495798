import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GatewayState } from './gateway.reducer';

export const getgatewayState = createFeatureSelector<GatewayState>('gateway');

export const getGateWayResponse = createSelector(
  getgatewayState,
  (state: GatewayState) => state.getGateway_Response
);

export const getGatewayListSucess = createSelector(
  getgatewayState,
  (state: GatewayState) => state.getGatewayListSucess
);

export const creategatewayResponse = createSelector(
  getgatewayState,
  (state: GatewayState) => state.creategateway_Response
);

export const deleteGateWayResponse = createSelector(
  getgatewayState,
  (state: GatewayState) => state.deleteGateWayResponse
);

export const updateGateWayResponse = createSelector(
  getgatewayState,
  (state: GatewayState) => state.updateGateWayResponse
);
export const getAllGateway_Response = createSelector(
  getgatewayState,
  (state: GatewayState) => state.getAllGateway_Response
);


export const getGatewayExportResponse = createSelector(
  getgatewayState,
  (state: GatewayState) => state.getGatewayExportResponse
);

/// 