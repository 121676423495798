import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../../shared/shared.service';
import { LoaderService } from '../../../../core/services/loader.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { createGateway, updateGateWay } from '../../../../store/GateWay/gateway.action';
import { Validations } from '../../../../shared/constants/validations';
Validations
@Component({
  selector: 'app-rp-create-gateway',
  templateUrl: './rp-create-gateway.component.html',
  styleUrl: './rp-create-gateway.component.scss'
})
export class RpCreateGatewayComponent {
  prifileValidations = Validations.gatewayPage;
  creategatewayForm!: UntypedFormGroup;
  currentUserData: any;
  submitted: boolean = false;
  selectedGateway: any;
  @Input() projectId: any;
  @Input() gatewayUpdateData: any;
  configData: any;
  gatewayProvidersList: any[] = []
  regex = /^[a-zA-Z0-9]+$/;
  constructor(
    private router: Router,
    private store: Store,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.creategatewayForm = this.formBuilder.group({
      serialNumber: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(40), Validators.pattern(this.regex)]],
      gatewayName: [null],
    });
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
    }

  }

  ngOnInit(): void {
    console.log('', this.projectId)

    this.getAllConfigData();

  }
  get gatewayControls() {
    return this.creategatewayForm.controls;
  }


  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (data.statusCode == 200 && data.message == 'Config data retrieved successfully.') {
        this.configData = data.data.gatewayProviders;
        console.log(this.gatewayUpdateData)
        this.creategatewayForm.patchValue({
          serialNumber: this.gatewayUpdateData?.serialNumber ? this.gatewayUpdateData.serialNumber : '',
          gatewayName: this.gatewayUpdateData?.gatewayProviderId ? this.gatewayUpdateData.gatewayProviderId : ''
        });
        if (Object.keys(this.gatewayUpdateData ?? {}).length > 0) { // upadte data
          /*  this.gatewayProvidersList.forEach((x: any) => {
             if (x.name == this.gatewayUpdateData.name) {
               this.selectedGateway = x
             }
           }) */

        }
        console.log('Config data retrieved successfully', this.configData)

      }
    });
  }

  valueChange(value: any): void {
    console.log("valueChange", value);
    this.selectedGateway = value;
  }

  gateWayNameFilter(value: any) {
    this.gatewayProvidersList = this.configData.filter(
      (s: { name: string; }) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  saveGateway(type: string) {
    this.submitted = true;
    if (this.creategatewayForm.valid) {
      this.loaderService.setLoading(true);
      let payload = {
        serialNumber: this.creategatewayForm.get('serialNumber')?.value,
        gatewayProviderId: this.creategatewayForm.get('gatewayName')?.value,
        projectId: this.projectId
      }
      console.log(payload);
      //console.log(this.creategatewayForm.value);
      if (type == 'Create') {
        this.store.dispatch(createGateway({ gateway: payload })
        );
      } else if (type == 'Update') {
        let payload = {
          serialNumber: this.creategatewayForm.get('serialNumber')?.value,
          gatewayProviderId: this.creategatewayForm.get('gatewayName')?.value,
          id: this.gatewayUpdateData.id
        }
        console.log(this.creategatewayForm.value);
        this.store.dispatch(updateGateWay({ updatedData: payload })
        );
      }
      this.modalService.hide();
    }
  }

  dismissModal(): void {
    this.modalService.hide();
  }
}
