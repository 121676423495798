import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectRoutingModule } from './projects-routing.module';
import { RpProjectsViewComponent } from './rp-projects-view/rp-projects-view.component';
import { RpCreateProjectComponent } from './rp-create-project/rp-create-project.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { UploadsModule } from '@progress/kendo-angular-upload';

// import { DropzoneModule } from 'ngx-dropzone-wrapper';
// import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
// import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { SharedModule } from '../../shared/shared.module';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { RpCreateMonitoringComponent } from './project-details/rp-Create-Monitoring/rp-Create-Monitoring.component';
// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//   // Change this to your upload POST address:
//   url: '',
//   maxFilesize: 50,
//   acceptedFiles: 'image/*'
// };
import { RpCreateGatewayComponent } from './project-details/rp-create-gateway/rp-create-gateway.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ComponentsModule } from '../../shared/components/components.module';

@NgModule({
  declarations: [
    RpProjectsViewComponent,
    RpCreateProjectComponent,
    ProjectDetailsComponent,
    RpCreateMonitoringComponent,
    RpCreateGatewayComponent,
  ],
  imports: [
   
    CommonModule,
    NgSelectModule,
    NgStepperModule,
    ProjectRoutingModule,
    CdkStepperModule,
    BsDatepickerModule.forRoot(),
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    UploadsModule,
    SharedModule,
    DropDownsModule,
    LabelModule,
    TooltipModule, ComponentsModule,
    UiSwitchModule,
    // DropzoneModule
  ],

  // providers: [
  //   {
  //   provide: DROPZONE_CONFIG,
  //   useValue: DEFAULT_DROPZONE_CONFIG
  // }]
})
export class ProjectModule { }
