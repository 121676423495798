export const MonitorData = [
    { ProjectCode: 'RJ1920212120024', ProjectName: 'UP2620157945263', Region: 'Nagaland', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active',},
    { ProjectCode: 'RJ1920212120023', ProjectName: 'MH2519959641799', Region: 'Tripura', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120022', ProjectName: 'LA0120208156103', Region: 'Mizoram', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120021', ProjectName: 'MP6419918182216', Region: 'Mizoram', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120020', ProjectName: 'PB6519874448570', Region: 'Mizoram', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: 'RJ1920212120019', ProjectName: 'WB3020073807862', Region: 'Tripura', Utility:'Water', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: 'RJ1920212120018', ProjectName: 'MP4720094952886', Region: 'Mizoram', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120017', ProjectName: 'AP0920035955651', Region: 'Tripura', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120016', ProjectName: 'DL0520147421233', Region: 'Mizoram', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    { ProjectCode: 'RJ1920212120015', ProjectName: 'PB2020197910921', Region: 'Nagaland', Utility:'Electricity', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120014', ProjectName: 'PB2020197910922', Region: 'Nagaland', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Active', },
    { ProjectCode: 'RJ1920212120013', ProjectName: 'PB2020197910927', Region: 'Tripura', Utility:'Gas', BillDate:'16-04-1960', ProjectType:'John Doe', ProjectStatus:'Inactive', },
    
];