import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MonitoringState } from './monitoring.reducer';

export const getMasterState =
  createFeatureSelector<MonitoringState>('monitoring');

export const getMonitoringResponse = createSelector(
  getMasterState,
  (state: MonitoringState) => state.getMonitoringResponse
);
export const getAllMonitoringResponse = createSelector(
  getMasterState,
  (state: MonitoringState) => state.getAllMonitoringResponse
);
export const createMonitoringResponse = createSelector(
  getMasterState,
  (state: MonitoringState) => state.createMonitoringResponse
);

export const updateMonitoringResponse = createSelector(
  getMasterState,
  (state: MonitoringState) => state.updateMonitoringResponse
);

export const deleteMonitoringResponse = createSelector(
  getMasterState,
  (state: MonitoringState) => state.deleteMonitoringResponse
);
