<!-- <div bsModal tabindex="-1">
  <div class="modal-dialog modal-dialog-centered"> -->



<div class="modal-content">
  <div class="modal-header bg-primary text-white ">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
      (click)="dismissModal()"></button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="createMonitoringForm">
      <div class="mb-3">
        <label>Meter Number <span class="required">*</span></label>
        <input type="text" formControlName="meterNumber" id="meterNumber" class="form-control" />
        @if(submitted && createMonitoringForm.controls['meterNumber'].errors) {
        <div class="invalid-feedback">
          @if(createMonitoringForm.controls['meterNumber'].errors['required']) {
          <div>{{Monitoring_Validations.MeterNumber.required}}</div>}
          @if(createMonitoringForm.controls['meterNumber'].errors['minlength']) {
          <div>{{Monitoring_Validations.MeterNumber.minLengthValidator}}</div>}
          @if(createMonitoringForm.controls['meterNumber'].errors['maxlength']) {
          <div>{{Monitoring_Validations.MeterNumber.MaxLengthValidator}}</div>}
          @if(createMonitoringForm.controls['meterNumber'].errors['pattern']) {
          <div>{{Monitoring_Validations.MeterNumber.patternValidation}}</div>}
        </div>}
      </div>

      <div class="mb-3">
        <label>Utility <span class="required">*</span></label>
        <ng-select [items]="configData?.data?.utilities" bindLabel="name" bindValue="id" formControlName="utilityId"
          id="type" [multiple]="false"></ng-select>
        @if(submitted && createMonitoringForm.controls['utilityId'].errors) {
        <div class="invalid-feedback">
          @if(createMonitoringForm.controls['utilityId'].errors['required']) {
          <div>{{Monitoring_Validations.utilityId.required}}</div>}
        </div>}

      </div>


    </form>


  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
    @if(monitoringData?.meterNumber){ <button type="button" class="btn btn-primary waves-effect waves-light"
      (click)="CreateMonitoring('Update')">Update</button>}
    @else{ <button type="button" class="btn btn-primary waves-effect waves-light"
      (click)="CreateMonitoring('Create')">Save</button> }
  </div>
</div><!-- /.modal -->
<!-- </div>
</div> -->